import { Component, OnInit } from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import { AuthService } from '../../services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { NavService } from '../../services/nav.service';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ModalController } from '@ionic/angular';
import { InviteDetailPage } from '../invite-detail/invite-detail.page';
import { ToastController} from '@ionic/angular';
import { AlertController } from '@ionic/angular';
import { CartModalPage } from '../cart-modal/cart-modal.page';
import { CartService } from '../../services/cart.service';
//import { AnyTxtRecord } from 'dns';


@Component({
  selector: 'app-invites',
  templateUrl: './invites.page.html',
  styleUrls: ['./invites.page.scss'],
})
export class InvitesPage implements OnInit {
  cartItemCount: BehaviorSubject<number> = this.cartService.getCartItemCount();
  uid = this.authService.getUid(); 
  loading = true;
  error: any;
  suppliers: any[];
  invites: any[];
  Suppliers: any[];
  tasks: any[];
  dataReturned: string[];
  supplierID:any;


  constructor(private authService: AuthService, 
    private afAuth: AngularFireAuth,
    private navService: NavService,
    private apollo: Apollo,
    private modalController: ModalController,
    public toastController: ToastController,
    public alertController: AlertController,
    private cartService: CartService,
    private router: Router) {}

  ngOnInit() {
this.uid=this.uid
    this.apollo
    .watchQuery({
      query: gql`
         query Invites($supplierID:ID) {
      invites(where:{
        supplierID:$supplierID,
        is_active:true},options:{sort:{date_create:DESC}}

        
       
      
      
      ){
         	inviteID
    			supplierID
          supplierUID
    			count_accept
    			task
          is_active
          date_create
          inviteAccept{
            acceptID
            supplierID
            supplierUID
            acceptSupplier{
              supplierID
              uid
              email
              count_invites
            }
            
          }
    			inviteTask {
    			  taskID
    			  task_description
    			  task_detail
    			  task_why_now
            city_tag
    			  date_create
    			  is_active
    			  is_emergency
    			  
    			}
    
    			inviteItem {
    			  itemID
    			  item
    			  image_path
    			}
    			inviteCategory {
    			  categoryID
    			  category
    			  slug
    			  description
    			  image_path
    			  image_path_header
    			}
    				inviteSubcategory {
    				  subcategoryID
    				  subcategory
    				  
    				}
  }}	
 
      `,
             //variables: {uid:this.uid} , 
                 //variables: {supplierUID: this.uid} ,
                 variables:    {supplierID: this.afAuth.auth.currentUser.uid},
                 //variables:    {supplierID: this.uid},


         
    })
    .valueChanges.subscribe((result: any) => {
      this.invites = result?.data?.invites;
      this.loading = result.loading;
      this.error = result.error;
    });
    console.log('uid',this.uid)
    this.apollo
    .watchQuery({
      query: gql`
        query Supplier($uid:ID) {
  suppliers(where:{uid:$uid}){
    supplierID
    uid
    email
    userInvites{
      supplierID
      is_active
      acceptSupplierID
      inviteID
      task
      description
      taskID
      connectID
      date_create
      tasks{
          task_description
          task_detail
          zip_tag
          city_tag
          task_why_now

          
        }
      inviteAccept{
        date_create
        supplierID
        acceptID
        description
          acceptHome{
            email
              homeInfo{
                name_first
                name_last
              }
              homeAddress{
                street_number
                street
                city
                state
                zip
              }
          }
       
      }
      accepts{
        supplierID
        date_create
          
      }
      
      inviteFee{
                amount
              }
            inviteTask{
              city_tag
              category_tag
              taskItem{
                item
              }
              count_invites
              count_connect
              date_create
              task_description
              task_detail
              
              
                subcategory{
                subcategory
                  
                
        }
              
        taskItem{
          item
          image_path 	
        }
        


        taskCategory{
          category
        }
        
        
        taskSubcategory{
          subcategory
        }
        taskZip{
          town
        }
      }
    }
    
  }
}


`,
    variables:    {uid: this.uid},

})
    .valueChanges.subscribe((result: any) => {
      this.suppliers = result?.data?.suppliers;
      this.loading = result.loading;
      this.error = result.error;
    });
    console.log('uid',this.uid)
    //console.log('supplier',this.suppliers)

  }
  
  async openModal(inviteID) {
    const modal = await this.modalController.create({
      component: InviteDetailPage,
      id:"invitedetail",
      componentProps: {
        
        uid:this.uid,
        inviteID:inviteID
      }
    });
 
    modal.onDidDismiss().then((dataReturned) => {
      if (dataReturned !== null) {
        this.dataReturned = dataReturned.data;
        //alert('Modal Sent Data :'+ dataReturned);
      }
    });
 
    return await modal.present();
  }
  
close():void{
    this.modalController.dismiss();
  }

  async openCart() {
    const modal = await this.modalController.create({
      component: CartModalPage,
      cssClass: 'cart-modal'
    });
    modal.present();
}
addToCart(i) {
  this.cartService.addProduct(i);
}
closeModal():void{
  this.modalController.dismiss();
}
}