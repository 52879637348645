import { Component, OnInit, Input,AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import { AuthService } from '../../services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { NavService } from '../../services/nav.service';
import { LoadingController, ToastController,AlertController } from "@ionic/angular";
import { ActivatedRoute, Router} from '@angular/router';
import { ModalController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';
import { CartService } from '../../services/cart.service';
import { BehaviorSubject } from 'rxjs';
import { CartModalPage } from '../cart-modal/cart-modal.page';

import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import {H3Index} from "h3-js";
import {H3IndexInput} from "h3-js";
import {polygonToCells} from "h3-js";
import {cellsToMultiPolygon} from "h3-js";
//mport {h3} from "h3-js";
import {gridDisk} from "h3-js";
import {cellToBoundary} from "h3-js";
import {latLngToCell} from "h3-js";
import {cellToLatLng} from "h3-js";
import {isPentagon} from "h3-js";
import {getResolution} from "h3-js";
import {isValidCell} from "h3-js";
import * as mapboxgl from 'mapbox-gl';
import { environment } from "../../../environments/environment";


mapboxgl.accessToken = 'pk.eyJ1IjoiZm1jbG91ZCIsImEiOiJjbDl5bjUzajkwNmt3M3ZseG5yMTRiaGc5In0.4XOmaw6L-ETJnL4s3boq5g';
//placekey  https://github.com/Placekey/placekey-js
import {geoToPlacekey} from '@placekey/placekey';
import {placekeyToGeo} from '@placekey/placekey';
import {placekeyToH3} from '@placekey/placekey';
import {h3ToPlacekey} from '@placekey/placekey';

import geojson2h3 from 'geojson2h3';
import { Observable } from 'rxjs';


//H3
import  { h3SetToFeatureCollection } from 'geojson2h3';
import {cellToChildren} from "h3-js";

import * as MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
let h3IndexToHighlight = '';
//const h3Input = document.getElementById('zoomToIndex');
const h3Input = '872a10545ffffff';

const GET_TODAYS = gql`
  query GetToday {
    todays{
  todayID
  month{
    monthID
    month
  }
  
        day{
            day
            dayID
            date
            weekNumber
            month
            year
        }
          week{
            weekID
            week
            banner_placement{
              bannerUID
              bannerName
              
            }
            promos_placement{
              promoUID
              promotionName
            }
           
  }
  }
 }
`;

@Component({
  selector: 'app-invite-detail',
  templateUrl: './invite-detail.page.html',
  styleUrls: ['./invite-detail.page.scss'],
})
export class InviteDetailPage implements OnInit {

  
  
  cartItemCount: BehaviorSubject<number> = this.cartService.getCartItemCount();
  @Input() dataArr: string;
  segmentModel = "specs";

  inviteID:any;
  uid = this.authService.getUid(); 
  invites: string[];
  items: string[];
  tasks: string[];
  task: string[];
  inviteTask:any[];

  suppliers: string[];
  loading = true;
  error: any;
  item:any;
  fee:any;
  //this added for cart checkout
  product:any;
  productID:any;
  amount:any;
  todays:any;
  today:any;
  day:any;
  dayID:any;
  weekID:any;
  week:any;
  data:any;
  result:any

  //map 

  h3Input:any;
  latitude:any;
  longitude:any;
  
  supplierID:any;
  h310:any[];
  h39:any[];
  h37:any[];
  h36:any[];
  h35:any[];
  h34:any[];
  h33:any[];
  h32:any[];
  h31:any[];
  h30:any[];
monthID:any;
  //map:any[];


  geomap:any[];

  
  private mapbox: mapboxgl.Map;
  private map = {};
  private directions = {};
  private container = {};
  private value: any;
  private values: any;

 
  constructor( private authService: AuthService, 
    private afAuth: AngularFireAuth,
    private navService: NavService,
    private apollo: Apollo,
    private modalController: ModalController,
    private activatedroute:ActivatedRoute,
    private router: Router,
    private toastController: ToastController,
    private cartService: CartService,
    private navParams: NavParams) {
      mapboxgl.accessToken = environment.mapboxgl.accessToken;

    console.log(JSON.stringify(navParams.get('dataArr')));
   }
   private querySubscription: Subscription;
   


  ngOnInit() {

    
  
    
    // this.dayID=12;
     this.day=this.dayID;
     this.week=this.weekID;
    this.monthID= this.navParams.get('monthID');
    let inviteID = this.navParams.get('inviteID');
    this.item = this.invites;
    //this.product.id = this.inviteID;
    this.amount = this.cartService.getItemCount(this.inviteID);
    console.log('inviteID',inviteID);
    console.log('uid',this.uid)
    console.log('amount',this.amount);
    console.log('dayID',this.dayID);
    console.log('monthID',this.monthID);


    this.apollo
    .watchQuery({
      query: gql`
         query todays
 {
 todays{
  todayID
  month{
    monthID
    month
  }
  
        day{
            day
            dayID
            date
            weekNumber
            month
            year
        }
          week{
            weekID
            week
            banner_placement{
              bannerUID
              bannerName
              
            }
            promos_placement{
              promoUID
              promotionName
            }
           
  }
  }
 }
 
      `,
             //variables: {uid:this.uid} , 
        // variables:    {uid: this.afAuth.auth.currentUser.uid},
         
    })
    .valueChanges.subscribe((result: any) => {
      this.todays = result?.data?.todays;
      this.loading = result.loading;
      this.error = result.error;
    });
    console.log('todays',this.todays)
   
    this.apollo
                  .watchQuery({
                    query: gql`
                       query Supplier($uid:ID) {
          suppliers(where:{uid:$uid}) 
                      {
            username
             supplierID
          
              uid
              email
              subscriptionConnect{
                supplierID
              }
              subscriptionTerritory{
                territoryID
                territory
              }
              subscriptionBusinessType{
                businessTypeID
                business_type
              }
              subscriptionTasks{
                taskID
                count_connect
                connectSupplierID
                territoryID
                task_description
                task_detail
                date_create
                mytaskConnects{
                  supplierID
                
                }
                  category{
                    category
                  }
                  subcategory{
                    subcategory
                  }
                  item{
                    item
                    image_path
                  }
                  zip{
                zip
                town
              }
              }
              
              

              territory{
                          territoryID
                          territory
                        }
              
                business_type	{
                  businessTypeID
                  image_path
                  business_type
                  
                
                  category	{
                    categoryID
                    image_path
                    category
                    connect{
                      connectID
                      task
                    }
                    tasks{
                      taskID
                      
                     
                      
                        subcategory{
                          subcategory
                          image_path
                          
                        }
                    
                    
                  }
                  
                }
           
            
              }
                      }}
  
  
            `,
                  variables:    {uid: this.afAuth.auth.currentUser.uid},
            
            })
                  .valueChanges.subscribe((result: any) => {
                    this.suppliers = result?.data?.suppliers;
                    this.loading = result.loading;
                    this.error = result.error;
                  });
                  
           
              

    
    this.apollo
    .watchQuery({
      query: gql`
       query Invites($inviteID:ID) {
          invites(where:{inviteID:$inviteID}){
            inviteID
            inviteSupplierID
            acceptSupplierID
            task
            description
      			date_create
      			schedule
            supplierID
            supplierUID
            homeID
            taskID
            connectID
            categoryID
            subcategoryID
            territoryID
      			note_to_supplier
                inviteTask{
                task_description
                city_tag
                date_create
                taskDaysAgo
                count_connect
                count_accept
                count_invites
                    taskLocation{
                    h39
                }
                
            }

            inviteAccept{
              supplierID
              supplierUID
              date_create

                acceptHome{
                  email
                  homeInfo{
                    name_first
                    name_last
                  }
                  homeAddress{
                    street_number
                    street
                    city
                    state
                    zip
                  }
                }
            }
      				inviteCategory{
                category
                image_path
              }
      				inviteSubcategory{
                subcategory
                
              }
      				inviteItem{
                item
                image_path
              }
      				inviteZip{
                town
                zip
              }
      				inviteFee{
        			amount
      				}
      				inviteTerritory{
                territory
              }
   
    
  }
}


`,
    //variables:    {uid: this.afAuth.auth.currentUser.uid},
    variables:    {inviteID:inviteID},
   //variables: {inviteID:this.activatedroute.snapshot.paramMap.get('inviteID')} ,

})
    .valueChanges.subscribe((result: any) => {
      this.invites = result?.data?.invites;
      this.loading = result.loading;
      this.error = result.error;
    });
   
  console.log('data',this.invites)
 
}
  closeModal():void{
    this.modalController.dismiss();
}
addToCart(i) {
  this.cartService.addProduct(i);
  
}

removeFromCart(i) {
  this.cartService.decreaseProduct(i);
}
segmentChanged(event){
  console.log(this.segmentModel);
  
  console.log(event);
}
async openCart(dayID,weekID,uid,monthID) {
  const modal = await this.modalController.create({
    component: CartModalPage,
    cssClass: 'my-modal-class',
    id:"cart",
    componentProps: {
      dayID:dayID,
      weekID:weekID,
      uid:uid,
      monthID:monthID
     
   
    }
  });
  modal.present();
  console.log('dayID',this.dayID);
  console.log('weekID',this.weekID);
  console.log('monthID',this.monthID);

}
showToast() {
  this.toastController.create({
    header: 'Success',
    message: 'Added to Cart!',
    position: 'bottom',
    cssClass: 'my-custom-class',
    color: 'pro',
    duration: 3000,
    buttons: [
      {
        side: 'end',
        icon: 'cart',
       
       
       
      }, {
        side: 'end',
        text: 'Close',
        role: 'cancel',
        handler: () => {
          console.log('Close clicked');
          this.closeModal()
        }
      }
    ]
  }).then((obj) => {
    obj.present();
  });
 


}






}
